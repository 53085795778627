import React from 'react';
import {Image} from "react-bootstrap";

class HostedBy extends React.Component{
    render(){
        return(
            <div className="HostedContainer">
                <div className="Hosted-internal">
                    <div className="Hosted-content">
                        <h2>Hosted By</h2>
                        <p>
                        The Association for Computing Machinery (ACM) is one of the largest student 
                        organizations at FIU. Our mission is to support our students’ professional 
                        and technical advancement through university studies and beyond. We have been 
                        recognized both locally and nationally for our Outstanding Chapter activities 
                        by ACM National.
                        </p>
                    </div>
                    <div className="Hosted-image">
                        <Image src={require("../../assets/images/acm-logo-white.png")} className="Hosted-logo" alt="ACM Logo" />
                    </div>
                </div>
            </div>
        );
    }
}

export default HostedBy;