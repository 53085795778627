import React from 'react';
import {Image} from "react-bootstrap";

class Banner extends React.Component {
    render(){
        return(
            <div className="PageHero">
                <div className="PH-header">
                    <div className="PH-hero">
                        <Image src={require("../../assets/images/MH-web-graphics-left.svg")} className="banner-hero" alt="Miamihacks hero" />
                    </div>
                    <div className="PH-content">
                        <Image src={require("../../assets/images/MiamiHacks_logo.svg")} className="banner-logo" alt="Miamihacks logo" />
                        <div className="banner-dates">
                            <h1>tech culture reinvented</h1>
                            <h2>October 2 - 4, 2020</h2>
                            <h3>Florida International University</h3>
                        </div>
                        <a className="reg-btn" href="https://miamihacks.typeform.com/to/VPEU7UZD" role="button">
                            Register
                        </a>
                        <div className="social-media-btn">
                            <a href="https://discord.gg/4vZBxg" role="button">
                                <Image src={require("../../assets/images/discord-icon.svg")} alt="discord-icon" />
                            </a>
                            <a href="https://www.twitter.com/acmfiu" role="button">
                            <Image src={require("../../assets/images/twitter-icon.svg")} alt="twitter-icon" />
                            </a>
                            <a href="https://www.facebook.com/acmfiu/" role="button">
                                <Image src={require("../../assets/images/facebook-icon.svg")} alt="facebook-icon" />
                            </a>
                            <a href="https://www.instagram.com/acmfiu/?hl=en" role="button">
                                <Image src={require("../../assets/images/instagram-icon.svg")} alt="instagram-icon" />
                            </a>
                            <a href="https://www.linkedin.com/company/association-for-computing-machinery-at-fiu/" role="button">
                                <Image src={require("../../assets/images/linkedin-icon.svg")} alt="linkedin-icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;