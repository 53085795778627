import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/style.css';
import Main from './components/main';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('root')
);


serviceWorker.unregister();
