import React from 'react';
import {Image} from "react-bootstrap";

class Sponsors extends React.Component{
    constructor(props){
        super(props);
        this.sponsors = [
          {
            name: "Google",
            alt: "Google Logo",
            logo: require("../../assets/images/Logo__Google_white.svg"),
            link: "https://google.com"
          },
          {
            name: "Facebook",
            alt: "Facebook Logo",
            logo: require("../../assets/images/facebook-white.svg"),
            link: "https://facebook.com"
          },
          {
            name: "General Electric",
            alt: "GE logo",
            logo: require("../../assets/images/general-electric-white.svg"),
            link: "https://www.ge.com/"
          },
          {
            name: "test.ai",
            alt: "test.ai logo",
            logo: require("../../assets/images/Test.ai_logo_white.svg"),
            link: "https://www.test.ai/"
          },
        //   {
        //     name: "Florida International University School of Computing",
        //     alt: "FIU SCIS logo",
        //     logo: require("../assets/img/SCIS-FIU-Colors.png"),
        //     link: "https://cis.fiu.edu"
        //   },
        {
          name: "Linode",
          alt: "Linode logo",
          logo: require("../../assets/images/Logo_Linode.svg"),
          link: "https://www.linode.com/"
        },
        // {
        //   name: "Linode",
        //   alt: "Linode logo",
        //   logo: require("../../assets/images/logo_revised_3.svg"),
        //   link: "https://www.linode.com/"
        // },
        //   {
        //     name: "Florida International University Honors Collage",
        //     alt: "FIU Honors logo",
        //     logo: require("../assets/img/fiu_honors_transparent.png"),
        //     link: "https://honors.fiu.edu"
        //   },
          {
            name: "Digital Ocean",
            alt: "Digital Ocean logo",
            logo: require("../../assets/images/DigitalOcean_logo_white.svg"),
            link: "https://digitalocean.com"
          },
          {
            name: "Glimpse",
            alt: "Glimpse logo",
            logo: require("../../assets/images/glimpse_logo_w_text_white.svg"),
            link: "https://www.joinglimpse.com/"
          },
          {
            name: "Florida International University School of Engineeering and Computing",
            alt: "FIU CEC logo",
            logo: require("../../assets/images/FIU_CEC_White.svg"),
            link: "https://cec.fiu.edu"
          },
          {
            name: "Stickermule",
            alt: "Stickermule logo",
            logo: require("../../assets/images/Logo__Stickermule_white.svg"),
            link: "http://hackp.ac/mlh-stickermule-hackathons"
          },
          {
            name: "Major League Hacking",
            alt: "MLH logo",
            logo: require("../../assets/images/Logo_MLH.svg"),
            link: "http://mlh.io"
          }
        ];
      }
    render(){
        return(
            <div className="SponsorsContainer">
                <div className="Sponsors-content">
                    <h2>Sponsors</h2>
                    <p>MiamiHacks is possible thanks to these amazing people:</p>
                    <div className="Sponsors-internal">
                        {this.sponsors.map((sponsor) => (
                            <div>
                                <Image src={sponsor.logo} alt={sponsor.alt}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Sponsors;