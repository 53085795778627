import React from 'react';

class FAQS extends React.Component{
    render(){
        return(
            <div className="FaqsContainer">
                <h2>FAQS</h2>
                <div className="FaqsInternal">
                    <div className="FaqsCols1">
                        <h3>WILL MIAMIHACKS BE ONLINE OR IN-PERSON THIS YEAR?</h3>
                        <p>
                            Due to the circumstances surrounding the COVID-19 pandemic, 
                            MiamiHacks has decided to host our first-ever online hackathon.
                        </p>
                    
                        <h3>WHAT PLATFORM WILL YOU BE USING TO HOST THE EVENT?</h3>
                        <p>
                            We are considering a multitude of options, currently, including Zoom, 
                            Discord and some new cutting-edge technologies!
                        </p>

                        <h3>WHO CAN COME?</h3>
                        <p>
                        If you’re currently a college student or have graduated in the past year, you're more than welcome to attend! 
                        Not a student but still want to participate? Reach out so you can attend as a mentor and help out our hackers! 
                        Mentor applications will be opening soon.
                        </p>

                    </div>
                    <div className="FaqsCols2">
                        <h3>HOW DO YOU PLAN TO KEEP THE SENSE OF COMMUNITY IN AN ONLINE EVENT? HOW WILL PEOPLE BE ABLE TO FORM TEAMS?</h3>
                        <p>
                            As with any hackathon, the most important way to foster community is through 
                            communication. We are planning to use our online platform to facilitate 
                            communication between hackers, time for forming teams, and opportunities to 
                            match with mentors. With the online event, we are foreseeing even more 
                            connections between hackers, and more meaningful interaction and guidance 
                            between hackers and mentors.
                        </p>

                        <h3>ARE YOU STILL LOOKING FOR SPONSORS?</h3>
                        <p>
                            We definitely are still welcoming corporate sponsors! While this is 
                            a new hackathon experience, we are confident that MiamiHacks can provide 
                            a meaningful and fulfilling experience for both hackers and sponsors. 
                            If you're interested in sponsoring, please send us an email to acmchapter@fiu.edu!
                        </p> 
                    </div>
                </div>
            </div>
        );
    }
}

export default FAQS;