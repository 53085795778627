/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';


class Footer extends React.Component {
    render(){
        return(
            <div className="FooterContainer">
                <div className="Footer-internal">
                    <h2>With 💛 from MiamiHacks</h2>
                </div>
                <div className="Footer-link">
                    <a href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf" alt="MLH code of conduct">MLH Code of Conduct</a>
                </div>
            </div>
        );
    }
}

export default Footer;