import React from 'react';

class About extends React.Component{
    render(){
        return(
            <div className="AboutContainer">
                <div className="About-header">
                    <h2>A place to build, learn and grow</h2>
                    <p>
                        MiamiHacks is a 36 hour hackathon that encourages learning, collaboration, growth, 
                        innovation, and fun. We will welcome 1000 students from across the globe, 
                        experienced mentors, and wonderful sponsors to create amazing projects.
                    </p>
                </div>
                <div className="About-content">
                    <h2>
                        What is MiamiHacks?
                    </h2>
                    <p>
                        MiamiHacks is a chance to meet new people, learn cool things, build
                        something new, dream big, and have fun!
                        
                        Everyone is welcome - from the most experienced hackers and builders
                        to the thinkers and the curious who have never heard of a hackathon.
                        Regardless of your experience, there is something for you at
                        MiamiHacks.
                        
                        We’d love for you to come learn something new, take the things you
                        love or care about and combine them with technology to make something
                        different, something cool, or something to improve the world.
                    </p>
                </div>
            </div>
        );
    }
}

export default About;