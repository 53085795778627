import React from 'react';
import Banner from './header/banner';
import About from './body/about';
import FAQS from './body/faqs';
import HostedBy from './body/hosted';
import Sponsors from './body/sponsors';
import Footer from './footer/footer';

export default function Main() {
    return(
        <React.Fragment>
            <div className="HomeContainer">
                <Banner />
                <About />
                <HostedBy />
                <Sponsors />
                <FAQS />
                <Footer />
            </div>
        </React.Fragment>
    );
}